const handleSingleCheckboxInputClick: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const singleCheckbox: any = event?.currentTarget || event?.target;
    const singleCheckboxContainer: any = singleCheckbox?.closest('.singleCheckboxInput');
    const singleCheckboxInput: any = singleCheckboxContainer?.getElementsByTagName('input')[0];
    const singleCheckboxValue: number = parseInt(singleCheckboxInput?.value);

    singleCheckboxContainer.classList.toggle('checked');
    singleCheckboxContainer.classList.remove('invalid');

    if (singleCheckboxValue === 0) {
      singleCheckboxInput.value = '1';
    } else {
      singleCheckboxInput.value = '0';
    }
  } catch (error) {
    console.error(error);
  }
};

const setSingleCheckboxInputError: (singleCheckboxInputID: string) => void = singleCheckboxInputID => {
  try {
    const singleCheckboxInput: any = document.getElementById(singleCheckboxInputID);
    const parent: any = singleCheckboxInput?.closest('.singleCheckboxInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocateSingleCheckboxInputValueByIdToNewInputByIdAndReturnSuccess: (
  singleCheckboxInputID: string,
  newInputID: string
) => boolean = (singleCheckboxInputID, newInputID) => {
  try {
    const singleCheckboxInput: any = document.getElementById(singleCheckboxInputID);
    const newInput: any = document.getElementById(newInputID);
    const singleCheckboxInputValue: string = singleCheckboxInput?.value || '';

    newInput.value = singleCheckboxInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
